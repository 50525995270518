<template >
  <div class="home">

    <section class="z10">

      <div class="back-header">
        <canvas id="canvas-complex"></canvas>
      </div>
      <div class="header">
        <!--
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon fill="white" points="0,100 100,0 100,100"/>
        </svg>-->
        <div class="Section__layoutContainer">
          <div class="Section__layout">
            <div class="ColumnLayout two">
              <div>
                <h1>
                  The new standard in affiliate networks
                </h1>
                <p>Paynura is an affiliate network for ewallets, poker, casino, and sportsbook. We take care of the
                  package so that you can grow in clients and revenue.
                </p>
                <a class="button">Start now</a>
                <a class="button outline">Contact us</a>
              </div>
              <div>
                <img src="../assets/the_new_standard_image.png" class="head" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="guidelines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="Section__layoutContainer">

        <div class="Section__title">
          <h1 class="align-center">Exclusive affiliate solution for
            gaming and sportsbook</h1>
        </div>
        <div class="Section__layout smaller-top-padding">
          <div class="ColumnLayout four">
            <div>
              <div class="info__Wrap">
                <img src="../assets/affiliate_thumb_1.png" >
                <h2>Flexible affiliate deal</h2>
                <p>Our affiliates have access to
                  highly competitive and flexible
                  revenue share deals or CPA
                  on request. </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <img src="../assets/affiliate_thumb_2.png" >
                <h2>Fast commission payouts</h2>
                <p>Our affiliates have access to
                  highly competitive and flexible
                  revenue share deals or CPA
                  on request. </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <img src="../assets/affiliate_thumb_3.png" >
                <h2>Dedicated support</h2>
                <p>
                  Because we have worked with
                  gaming brands for over 15
                  years, we can offer exclusive
                  support to our affiliates and the
                  benefits that go with it.
                </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <img src="../assets/affiliate_thumb_4.png" >
                <h2>Affiliate payment methods</h2>
                <p>
                  We offer multiple options for
                  commission payments. Affiliates
                  can choose between Skrill,
                  Neteller, or Bitcoin accounts.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="grey" >
      <div class="Section__layoutContainer">
        <div class="Section__layout bigger-bottom-padding make-container-for-absoulute">
          <div class="ColumnLayout two align-center">
            <div style="position:relative; ">
              <div class="slide-up-down-1">
                <img src="../assets/detailed_reporting_image2.png"  style="width: 90%;transform: translatey(0px);
	animation: float 6s ease-in-out infinite;"  />
              </div>
              <div class="slide-up-down-2">
                <img src="../assets/detailed_reporting_image_1.png"  style="width: 90%;transform: translatey(0px);
	animation: float 5s ease-in-out infinite;"  />
              </div>
            </div>

            <div class="text-left Section__title sub-rule-for-absolute">
              <h1>
                Detailed reporting
              </h1>
              <p>Access to our state-of-the-art affiliate backend
                system with transparent analytics.
              </p>
              <ul class="default-list">
                <li>Daily overview of deposits, revenue and commission</li>
                <li>Flexible performance reporting</li>
                <li>Affiliate offers and marketing tools</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="colored z10" style="transform: skewY(-4deg); margin-top:-50px;">
      <div class="Section__layoutContainer" style="transform: skewY(4deg);">
        <div class="Section__layout">
          <div class="ColumnLayout two align-center">
            <div class="text-left Section__title">
              <h1>
                Payment on request
              </h1>
              <p>A transparent overview of available commission is part
                of the smart payment management system.

              </p>
              <div class="in-content-comment">
                <p>"No more waiting for the monthly commision.
                  With Paynura you get paid, whey you say so.”</p>
                <div class="author">
                  <img src="../assets/avatar.png" /> <span>Jaloon G. - Master Affiliate</span>
                </div>
              </div>
            </div>
            <div style="overflow: hidden">
              <img src="../assets/payment_request_image.png" class="payments-image" />
            </div>

          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="guidelines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="Section__layoutContainer">
        <div class="Section__layout" style="padding-left:0px; padding-right:0px; padding-bottom:60px; ">
          <div class="ColumnLayout two">
            <div class="Section__title text-left add-padding-desktop">
              <h1>
                Scale Up Revenue
              </h1>
              <p>Paynura offers poker, casino, sportsbook, and ewallet
                deals. You can pair offers to create double revenue
                flow from a single player or invite sub-affiliates.

              </p>
            </div>
            <div class="text-left ">

              <ul class="default-list margin-top">
                <li>Daily overview of deposits, revenue and commission</li>
                <li>Flexible performance reporting</li>
                <li>Affiliate offers and marketing tools</li>
              </ul>
            </div>
          </div>


        </div>
      </div>
    </section>
    <section>

      <div class="Section__layoutContainer">
        <div class="Section__layout remove-padding">
          <div class="ColumnLayout four brands">
            <div>
              <div><img src="../assets/logo_skrill.png" /></div>
              <div><img src="../assets/logo_coral.png" /></div>
            </div>
            <div>
              <div><img src="../assets/logo_partypoker.png" /></div>
              <div><img src="../assets/logo_betfair.png" /></div>
            </div>
            <div>
              <div><img src="../assets/logo_bet365.png" /></div>
              <div><img src="../assets/logo_888.png" /></div>
            </div>
            <div>
              <div><img src="../assets/logo_neteller.png" /></div>
              <div><img src="../assets/logo_guts.png" /></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="guidelines" style="top: -354px;">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="Section__layoutContainer">
        <div class="Section__layout" style="padding-top: 30px">
          <div class="Section__title text-center">
            <h1 class="align-center">
              Exclusive Support
            </h1>
            <p>Promoting gaming bands has opportunities and challenges.<br/>
              We are here to support you with both.

            </p>
          </div>
          <div class="ColumnLayout four">
            <div class="support_wrap">
              <div>
                <img src="../assets/icon_skype.png" />
              </div>
              <div>
                <span>Skype</span>
                <label>Short skype text</label>
              </div>
            </div>
            <div class="support_wrap">
              <div>
                <img src="../assets/icon_telegram.png" />
              </div>
              <div>
                <span>Telegram</span>
                <label>Short telegram text</label>
              </div>
            </div>
            <div>
              <div class="support_wrap">
                <div>
                  <img src="../assets/icon_email.png" />
                </div>
                <div>
                  <span>Email</span>
                  <label>Short email text</label>
                </div>
              </div>
            </div>
            <div>
              <div class="support_wrap">
                <div>
                  <img src="../assets/icon_livechat.png" />
                </div>
                <div>
                  <span>Live Support</span>
                  <label>Short support text</label>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
    <section class="blue-gradient">
      <div class="guidelines secondary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="Section__layoutContainer">

        <div class="Section__layout " style="padding-bottom: 70px; padding-top:400px;">
          <div class="ColumnLayout four">
            <div>
              <div class="info__Wrap">
                <h3>300+</h3>
                <p>Registered gaming affiliates
                  from all continents.
                </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <h3>5</h3>
                <p>Focus on five verticals: poker,
                  casino, sportsbook, trading, and
                  ewallets.
                </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <h3>145,000+</h3>
                <p>
                  Tagged accounts created via
                  our affiliates and sub-affiliates.
                </p>
              </div>
            </div>
            <div>
              <div class="info__Wrap">
                <h3>8,5M</h3>
                <p>
                  Annual commission payment
                  volume made to our affiliates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blue-flat">
      <div class="Section__layoutContainer">
        <div class="Section__layout ">
          <div class="Section__title ">
            <h2 class="align-center">
              Feedback
            </h2>
            <div>
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="comment-wrap">
                      <div class="person">
                        <img src="../assets/avatar.png" />
                        <div>
                          <span class="name">Jaalon G.</span>
                          <span class="sub-name">Master Affiliate</span>
                        </div>
                      </div>
                      <div class="commetary">
                        <p>"Ewallet upgrades are very much needed these days and
                          Paynura can offer us in online poker business exactly that, a
                          transparent system with fast tracking and on time payments
                          with reliable support."</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blue-flat-gradient">
      <div class="Section__layoutContainer">
        <div class="Section__layout ">
          <div class="Section__title ">
            <h2 class="align-center">
              Ready to get started?<br>
              Create account or get in touch
            </h2>
            <div class="text-center" style="margin-top:50px;">
              <a class="button mr-4">Start now</a>
              <a class="button outline">Contact us</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="grey" style="">
      <div class="guidelines">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="footer-wrap">
        <div class="single">
          <h4>Paynura</h4>
          <ul>
            <li>
              <a href="">Sign up</a>
            </li>
            <li>
              <a href="">English</a>
            </li>
          </ul>
        </div>
        <div class="single">
          <h4>Affiliate Network</h4>
          <ul>
            <li>
              <a href="">Affiliate solutions</a>
            </li>
            <li>
              <a href="">Poker</a>
            </li>
            <li>
              <a href="">Casino</a>
            </li>
            <li>
              <a href="">Sportbook</a>
            </li>
            <li>
              <a href="">Ewallets</a>
            </li>
          </ul>
        </div>
        <div class="single">
          <h4>Company</h4>
          <ul>
            <li>
              <a href="">About</a>
            </li>
            <li>
              <a href="">Partnership</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
          </ul>
          <h4>Legal</h4>
          <ul>
            <li>
              <a href="">Terms & Conditions</a>
            </li>
            <li>
              <a href="">Privacy & Cookies</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
          </ul>
        </div>
        <div class="single">
          <h4>Resources</h4>
          <ul>
            <li>
              <a href="">Support</a>
            </li>
            <li>
              <a href="">News</a>
            </li>
            <li>
              <a href="">FAQ</a>
            </li>
          </ul>
          <h4>Social</h4>
          <ul>
            <li>
              <a href="">Facebook</a>
            </li>
            <li>
              <a href="">Instagram</a>
            </li>
            <li>
              <a href="">Youtube</a>
            </li>
            <li>
              <a href="">Linkedin</a>
            </li>
            <li>
              <a href="">Twitter</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="copyright">
        <div class="wrap">
          <div>Copyright 2020</div>
          <div>

          </div>
        </div>

      </div>
    </footer>
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
