import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
global.$ = jQuery
import 'bootstrap/dist/js/bootstrap.js';
import 'granim/dist/granim.js';
import 'bootstrap/dist/css/bootstrap.css';
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


var granimInstance = new Granim({
  element: '#canvas-complex',
  direction: 'radial',
  isPausedWhenNotInView: true,
  states : {
    "default-state": {
      gradients: [
        [
          { color: '#833ab4', pos: .2 },
          { color: '#fd1d1d', pos: .8 },
          { color: '#7226df', pos: 1 }
        ], [
          { color: '#3f8cde', pos: 0 },
          { color: '#766cdc', pos: .2 },
          { color: '#862165', pos: .75 }
        ],
      ]
    }
  }
});

$(document).ready(function fixedHeader() {

  var windows = $(window);
  var screenSize = windows.width();
  var sticky = $('.navbar');
  var $html = $('html');
  var $body = $('body');

  windows.on('scroll', function () {
    var scroll = windows.scrollTop();
    var headerHeight = sticky.height();

    if (screenSize >= 320) {
      if (scroll < headerHeight) {
        sticky.removeClass('is-sticky');
      } else {
        sticky.addClass('is-sticky');
      }
    }
  });
});

